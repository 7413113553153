<template>
  <section id="phone-wizard">
    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'onboarding' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :active="true">
              Phone Settings
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col md="6">
        <h2 class="p-2">
          <feather-icon
            icon="FolderIcon"
            size="24"
          />
          Phone Settings
        </h2>
      </b-col>
    </b-row>

    <!--form wizard-->
    <b-row class="match-height">
      <b-col class="mt-2">
        <b-overlay
          variant="transparent"
          :show="firstLoading"
        >
          <template #overlay>
            <div class="d-flex align-items-center">
              <b-spinner
                small
                type="grow"
                variant="secondary"
              />
              <b-spinner
                type="grow"
                variant="dark"
              />
              <b-spinner
                small
                type="grow"
                variant="secondary"
              />
              <!-- We add an SR only text for screen readers -->
              <span class="sr-only">Please wait...</span>
            </div>
          </template>

          <form-wizard
            ref="phoneWizard"
            color="#9933cc"
            :title="null"
            :subtitle="null"
            :start-index="wizardStep"
            layout="vertical"
            finish-button-text="Go to Conversions"
            back-button-text="Previous"
            class="wizard-vertical mb-3"
            @on-complete="finalizeWizard"
          >
            <!-- destination tab -->
            <tab-content
              title="Destination"
              :before-change="checkStepDestination"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Destination Number
                  </h5>
                  <small class="text-muted">
                    Enter the default destination number your Twilio phone numbers will lead
                    to.
                  </small>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Select Destination Number"
                    label-for="select-destination-number"
                  >
                    <b-form-select
                      id="select-destination-number"
                      v-model="selectedDestinationNumber"
                      :options="userDestinationNumbers"
                      text-field="phone_number"
                      value-field="id"
                      :select-size="4"
                    />
                  </b-form-group>
                  <b-button
                    v-b-modal.modal-destination
                    variant="primary"
                    class="mb-2"
                  >
                    Add New Number
                  </b-button>
                </b-col>
                <b-col md="12">
                  <p
                    v-if="wizardErrorStepDestination"
                    class="text-danger"
                  >
                    {{ wizardErrorStepDestination }}
                  </p>
                </b-col>
              </b-row>
            </tab-content>

            <!-- domain tab -->
            <tab-content
              title="Domain"
              :before-change="checkStepDomain"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Domain
                  </h5>
                  <small class="text-muted">
                    Select the domain where you will be displaying your phone number.
                  </small>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Select Domain"
                    label-for="select-domain"
                  >
                    <b-form-select
                      id="select-domain"
                      v-model="selectedDomain"
                      :options="userDomains"
                      text-field="domain"
                      value-field="domain"
                      :select-size="4"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <p>
                    Note: The number will not work if you post it on a domain different from the one you select.
                  </p>
                  <p
                    v-if="wizardErrorStepDomain"
                    class="text-danger"
                  >
                    {{ wizardErrorStepDomain }}
                  </p>
                </b-col>
              </b-row>
            </tab-content>

            <!-- locality tab -->
            <tab-content
              title="Location"
              :before-change="checkStepLocality"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Enter your location details
                  </h5>
                  <small class="text-muted">
                    Please provide your country, region and locality so we can provide local
                    numbers.
                  </small>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Country"
                    label-for="country"
                  >
                    <country-select
                      id="country"
                      v-model="country"
                      :country="country"
                      top-country="CA"
                      class="form-control"
                    />
                  </b-form-group>
                  <b-form-group
                    label="State/Province"
                    label-for="region"
                  >
                    <region-select
                      id="region"
                      v-model="region"
                      :country="country"
                      :region="region"
                      class="form-control"
                    />
                  </b-form-group>
                  <b-form-group
                    label="City"
                    label-for="locality"
                  >
                    <b-form-input
                      id="locality"
                      v-model="locality"
                      placeholder="City"
                    />
                  </b-form-group>
                </b-col>
                <!-- <b-col md="12">
                  <b-button
                    variant="primary"
                    class="mb-2"
                    @click="submitLocality"
                  >
                    Submit Location
                  </b-button>
                </b-col> -->
                <b-col md="12">
                  <p
                    v-if="wizardErrorStepLocality"
                    class="text-danger"
                  >
                    {{ wizardErrorStepLocality }}
                  </p>
                </b-col>
              </b-row>
            </tab-content>

            <!-- call rate tab -->
            <tab-content
              title="Call Rate"
              :before-change="checkStepCalls"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    How many calls do you get daily?
                  </h5>
                  <small
                    class="text-muted"
                  >Help us determine how many Twilio numbers to assign to you</small>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="How many phone numbers do you need?"
                    label-for="total-numbers"
                  >
                    <b-form-radio
                      v-model="totalNumbers"
                      name="total-numbers"
                      value="1"
                    >
                      1
                    </b-form-radio>
                    <b-form-radio
                      v-model="totalNumbers"
                      name="total-numbers"
                      value="2"
                    >
                      2
                    </b-form-radio>
                    <b-form-radio
                      v-model="totalNumbers"
                      name="total-numbers"
                      value="3"
                    >
                      3
                    </b-form-radio>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <p
                    v-if="wizardErrorStepCalls"
                    class="text-danger"
                  >
                    {{ wizardErrorStepCalls }}
                  </p>
                </b-col>
              </b-row>
            </tab-content>

            <!-- paste script tab -->
            <tab-content title="Add Script">
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Add the script to your website
                  </h5>
                  <small class="text-muted">Paste this code into your site to display your trackable phone number.</small>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Paste this code into your site header"
                    label-for="embedScript"
                  >
                    <b-form-textarea
                      id="embedScript"
                      rows="2"
                      readonly
                      :value="parseHtml(scriptEmbed)"
                    />
                  </b-form-group>
                  <b-button
                    class="btn btn-secondary mb-2"
                    @click="copyText(scriptEmbed)"
                  >
                    Click to Copy
                  </b-button>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Paste this code wherever you want to show a phone number"
                    label-for="embedScript"
                  >
                    <b-form-textarea
                      id="embedScript"
                      rows="2"
                      readonly
                      :value="parseHtml(phoneLocalEmbed)"
                    />
                  </b-form-group>
                  <b-button
                    class="btn btn-secondary mb-2"
                    @click="copyText(phoneLocalEmbed)"
                  >
                    Click to Copy
                  </b-button>
                </b-col>
              </b-row>
            </tab-content>

            <!-- verify tab -->
            <tab-content title="Verify">
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Verify your numbers
                  </h5>
                  <small
                    class="text-muted"
                  >Call your assigned number(s) to check if everything's working</small>
                </b-col>
                <!-- <b-col
                  cols="12"
                  class="mb-2"
                >
                  <b-alert
                    variant="danger"
                    :style="{ padding: '1rem' }"
                    show
                  >
                    <b>Error!</b> Assigned Twilio numbers not found.
                  </b-alert>
                </b-col> -->
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  Call your number(s), which are listed below. If everything's working, the lead will be displayed
                  in
                  <b-link :to="{ name: 'browse-conversions' }">
                    Conversions
                  </b-link>.
                </b-col>
              </b-row>
            </tab-content>

            <template
              slot="footer"
              slot-scope="props"
            >
              <div class="wizard-footer-left">
                <!-- <b-button
                  v-if="props.activeTabIndex > 0 && !props.isLastStep"
                  variant="outline-secondary"
                  @click="props.prevTab()"
                >
                  Previous
                </b-button> -->
                <b-button
                  v-if="userAssignedNumbers.length > 0 && props.activeTabIndex !== 4"
                  variant="outline-secondary"
                  @click="goToEmbed"
                >
                  View Embed Script
                </b-button>
              </div>
              <div class="wizard-footer-right">
                <b-button
                  v-if="!props.isLastStep"
                  variant="primary"
                  class="wizard-footer-right"
                  @click="props.nextTab()"
                >
                  Next
                </b-button>

                <b-button
                  v-else
                  variant="primary"
                  class="wizard-footer-right finish-button"
                  @click="finalizeWizard"
                >
                  {{ props.isLastStep ? 'Done' : 'Next' }}
                </b-button>
              </div>
            </template>

          </form-wizard>
        </b-overlay>
      </b-col>
    </b-row>

    <b-card class="mb-2">
      <b-row>
        <b-col>
          <b-table
            hover
            responsive
            :items="userAssignedNumbers"
            :fields="assignedTableFields"
            show-empty
          >
            <template #empty="">
              <p class="text-center m-2">
                No assigned numbers found.
              </p>
            </template>

            <template #cell(locality)="data">
              {{ `${data.item.locality}, ${data.item.region}` }}
            </template>

            <template #cell(actions)="data">
              <b-button
                variant="outline-danger"
                size="sm"
                @click="deleteAssignedNumber(data.item.phone_number)"
              >
                Delete
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>

    <!-- destination numbers modal -->
    <b-modal
      id="modal-destination"
      centered
      title="Edit Destination Numbers"
      size="lg"
      hide-footer
    >
      <b-row>
        <b-col>
          <b-table
            hover
            responsive
            :items="userDestinationNumbers"
            :fields="destinationTableFields"
            class="mb-2"
            show-empty
          >
            <template #empty="">
              <p class="text-center m-2">
                No destination numbers found.
              </p>
            </template>

            <template #cell(twilio_phone_numbers)="data">
              {{ data.item.twilio_phone_numbers.length }}
            </template>

            <template #cell(actions)="data">
              <b-button
                variant="outline-danger"
                size="sm"
                @click="deleteDestinationNumber(data.item.phone_number)"
              >
                Delete
              </b-button>
            </template>
          </b-table>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="New Destination Number"
            label-for="destination-number"
          >
            <VuePhoneNumberInput
              id="destination-number"
              v-model.trim="destinationNumberInput"
              default-country-code="CA"
              :preferred-countries="['CA', 'US']"
              @update="handleFormatMobile"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-button
            variant="outline-primary"
            class="mb-2"
            @click="submitDestinationNumber"
          >
            Submit New Number
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  // BAlert,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BButton,
  BLink,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSelect,
  BFormTextarea,
  // BFormInvalidFeedback,
  BModal,
  BOverlay,
  BTable,
  BSpinner,
} from 'bootstrap-vue'

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    // BAlert,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BButton,
    BLink,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormSelect,
    BFormTextarea,
    // BFormInvalidFeedback,
    BModal,
    BOverlay,
    BTable,
    BSpinner,

    // ValidationProvider,
    // ValidationObserver,
    FormWizard,
    TabContent,
    VuePhoneNumberInput,
  },
  data() {
    return {
      userAssignedNumbers: [],
      userDestinationNumbers: [],
      selectedDestinationNumber: null,
      destinationNumberInput: null,
      destinationNumber: null,
      userDomains: [],
      selectedDomain: null,
      country: '',
      region: '',
      locality: '',
      totalNumbers: null,
      twilioLocalNumbers: null,
      destinationTableFields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'phone_number', label: 'Phone Number', sortable: true },
        { key: 'twilio_phone_numbers', label: 'Twilio Numbers' },
        { key: 'actions', label: '' },
      ],
      assignedTableFields: [
        // { key: 'id', label: 'ID', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'locality', label: 'Locality', sortable: true },
        { key: 'phone_number', label: 'Twilio Number', sortable: true },
        { key: 'destination_number', label: 'Destination Number', sortable: true },
        { key: 'domain', label: 'Domain', sortable: true },
        { key: 'actions', label: '' },
      ],
      firstLoading: true,

      // form wizard
      wizardStep: 0,
      wizardErrorStepDestination: null,
      wizardErrorStepDomain: null,
      wizardErrorStepLocality: null,
      wizardErrorStepCalls: null,
      scriptEmbed: '&lt;script src="https://source.converifai.com/converifai.js">&lt;/script>',

      required,
      email,
    }
  },
  computed: {
    userData() {
      return this.$store.getters['users/getCurrentUser']
    },
    phoneLocalEmbed() {
      return `<span class="cvf-phone-local" country="${this.country}" region="${this.region}" locality="${this.locality}"></span>`
    },
  },
  mounted() {
    this.getAssignedTwilioNumbers()
    this.getDestinationNumbers()

    this.userDomains = this.userData.domains
  },
  methods: {
    firstLoadActions() {
      // actions when loading userAssignedNumbers for the first time
      if (this.userAssignedNumbers.length > 0) {
        // if phone numbers already exist, set locality
        this.country = this.userAssignedNumbers[0].country
        this.region = this.userAssignedNumbers[0].region
        this.locality = this.userAssignedNumbers[0].locality

        this.goToEmbed()
      }
      this.firstLoading = false
    },
    goToEmbed() {
      // jump to embed script tab
      this.$refs.phoneWizard.changeTab(0, 4)
    },
    getAssignedTwilioNumbers() {
      // get twilio numbers assigned to user
      useJwt
        .getAssignedNumbers()
        .then(response => {
          console.log('getAssignedNumbers', response.data.data)
          this.userAssignedNumbers = response.data.data
          if (this.firstLoading) {
            this.firstLoadActions()
          }
        })
        .catch(error => {
          console.log('getAssignedNumbers ERROR', error.response)
          this.handleErrorMessage(error.response.data.message || error.response.data.error)
        })
    },
    deleteAssignedNumber(phoneNumber) {
      // confirm alert
      this.$swal({
        title: 'Delete?',
        text: `The number ${phoneNumber} will be deleted.`,
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // delete twilio number from user
          useJwt
            .deleteAssignedNumber(phoneNumber)
            .then(() => {
              this.getAssignedTwilioNumbers()

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Twilio Number Deleted',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'You have successfully deleted the Twilio phone number.',
                },
              })
            })
            .catch(error => {
              console.log('deleteAssignedNumber ERROR', error.response)
              this.handleErrorMessage(error.response.data.message || error.response.data.error)
            })
        }
      })
    },
    getDestinationNumbers() {
      // get destination numbers from user
      useJwt
        .getDestinationNumbers()
        .then(response => {
          const numbersArray = response.data.data
          console.log('getDestinationNumbers', numbersArray)
          this.userDestinationNumbers = numbersArray
        })
        .catch(error => {
          console.log('getDestinationNumbers ERROR', error.response)
          this.handleErrorMessage(error.response.data.message || error.response.data.error)
        })
    },
    submitDestinationNumber() {
      // post the site tracking url
      if (this.destinationNumber) {
        useJwt
          .setDestinationNumber(this.destinationNumber)
          .then(response => {
            console.log('submitDestinationNumber', response.data.data) // null response

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Destination Number Set',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'You have successfully set your destination phone number.',
              },
            })

            this.destinationNumberInput = null
            this.getDestinationNumbers()
          })
          .catch(error => {
            console.log('submitDestinationNumber ERROR', error.response)
            this.handleErrorMessage(error.response.data.message || error.response.data.error)
          })
      } else {
        // error message
        this.wizardErrorStepDestination = 'You must submit a destination number before you can continue.'
      }
    },
    deleteDestinationNumber(phoneNumber) {
      // confirm alert
      this.$swal({
        title: 'Delete?',
        text: `The number ${phoneNumber} will be deleted.`,
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // delete destination number from user
          useJwt
            .deleteDestinationNumber(phoneNumber)
            .then(() => {
              this.getDestinationNumbers()

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Destination Number Deleted',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'You have successfully deleted the destination phone number.',
                },
              })
            })
            .catch(error => {
              console.log('deleteDestinationNumber ERROR', error.response)
              this.handleErrorMessage(error.response.data.message || error.response.data.error)
            })
        }
      })
    },
    getLocalNumbers() {
      // get local numbers from twilio
      if (this.country && this.region && this.locality) {
        useJwt
          .getAvailableLocalNumbers({
            country: this.country,
            region: this.region,
            locality: this.locality,
          })
          .then(response => {
            console.log('getAvailableLocalNumbers', response.data.data)
            this.twilioLocalNumbers = response.data.data
          })
          .catch(error => {
            console.log('getAvailableLocalNumbers ERROR', error.response)
            this.handleErrorMessage(error.response.data.message || error.response.data.error)
          })
      }
    },
    assignLocalNumbers() {
      // assign local numbers from twilio
      if (this.totalNumbers && this.twilioLocalNumbers && this.selectedDestinationNumber && this.selectedDomain) {
        const numberCount = this.totalNumbers
        const destinationId = this.selectedDestinationNumber
        const localityArray = {
          country: this.country,
          region: this.region,
          locality: this.locality,
          domain: this.selectedDomain,
        }

        for (let i = 0; i < numberCount; i++) {
          const phoneNumber = this.twilioLocalNumbers[i].phone_number
          this.setLocalNumber(phoneNumber, destinationId, localityArray)
        }
      }
    },
    setLocalNumber(phoneNumber, destinationId, locality) {
      // set local number
      useJwt.setLocalNumber(phoneNumber, destinationId, locality)
        .then(response => {
          console.log('setLocalNumber', response.data)

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Twilio Number ${phoneNumber} assigned`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'You have successfully assigned a phone number from Twilio.',
            },
          })
          this.getAssignedTwilioNumbers()
        })
        .catch(error => {
          console.log('setLocalNumber ERROR', error.response)
          this.handleErrorMessage(error.response.data.message || error.response.data.error)
        })
    },

    // wizard
    parseHtml(string) {
      const parsedString = string.replace(/&lt;/g, '<')
      return parsedString
    },
    copyText(string) {
      const textToCopy = this.parseHtml(string)
      navigator.clipboard.writeText(textToCopy)
    },
    finalizeWizard() {
      /* this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Check out your ad conversions here.',
          icon: 'EditIcon',
          variant: 'success',
        },
      }) */
      this.$router.push({ name: 'browse-conversions' })
    },
    checkStepDestination() {
      if (this.selectedDestinationNumber) {
        return true
      }
      this.wizardErrorStepDestination = 'You must select a destination number before you can continue.'
      return false
    },
    checkStepDomain() {
      if (this.selectedDomain) {
        return true
      }
      this.wizardErrorStepDomain = 'You must select a domain before you can continue.'
      return false
    },
    checkStepLocality() {
      if (this.country && this.region && this.locality) {
        this.getLocalNumbers()
        return true
      }
      this.wizardErrorStepLocality = 'You must input a country, region and city before you can continue.'
      return false
    },
    checkStepCalls() {
      if (this.totalNumbers && this.twilioLocalNumbers && this.selectedDestinationNumber && this.selectedDomain) {
        this.assignLocalNumbers()
        return true
      }
      this.wizardErrorStepCalls = 'You must select an option before you can continue.'
      return false
    },

    /* HANDLERS */
    handleFormatMobile(payload) {
      this.destinationNumber = payload.formattedNumber
    },
    handleErrorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'ErrorIcon',
          variant: 'danger',
          text: `${message}`,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
</style>
